/* ==========================================================================
   Statistics
   ========================================================================== */

.stats__header {
    display: flex;

    & h3 {
        font-weight: 500;
        color: var(--text-dark);
        font-size: 16px;
        margin: 0 0 12px 0;
    }

    .stats__menu {
        width: 160px;
        margin-left: auto;
        position: relative;
        top: -8px;
    }
}

.stats__body {
    display: grid;
    grid-gap: 16px;

    &.-quint {
        grid-template-columns: repeat(5, 1fr);

        &.-highlight {
            & .stats__box:first-child {
                grid-column: 1 / 2;
            }

            .stats__box:not(:first-child) {
                grid-column: 2 / span 5;
            }
        }

        .stats__box:not(.-highlight) {
            grid-column: 1 / span 5;
        }

    }

    &.-hext {
        grid-template-columns: repeat(6, 1fr);

        .stats__box {
            grid-column: 1 / span 6;
        }
    }

    &.-hext.-highlight {
        grid-template-columns: repeat(6, 1fr);

        .stats__box:first-child {
            grid-column: 1 / span 1;
        }

        .stats__box:not(:first-child) {
            grid-column: 2 / span 5;
        }
    }

    &.-database {
        grid-template-columns: repeat(6, 1fr);

        .stats__box:first-child {
            grid-column: 1 / span 4;
        }

        .stats__box:not(:first-child) {
            grid-column: 5 / span 2;
        }
    }

    &.-octo {
        grid-template-columns: repeat(8, 1fr);

        .stats__box {
            grid-column: 1 / span 8;
        }
    }

    &.-dbsource {
        grid-template-columns: repeat(5, 1fr);

        .stats__box:first-child {
            grid-column: 1 / span 2;
        }

        .stats__box:not(:first-child) {
            grid-column: 3 / span 3;
        }
    }
}

.stats__box {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--border-light);
    border-radius: 8px;
    padding: 16px 16px 10px 16px; /* accounts for line-height on bottom */
    min-height: 120px;

    .-highlight &:first-child {
        background-color: var(--colour-saffron-50);
        border: none;
    }
}

.stats__subtitle {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    color: var(--text-dark);
    margin-bottom: 24px;

    .-highlight .stats__box:first-child & {
        color: var(--colour-saffron-400);
    }
}

.stats__items {
    margin-top: auto;
    display: flex;

    & + .stats__items {
        margin-top: 32px;
    }
}

.stats__item {
    flex: 1;
}

.stats__value {
    font-size: 32px;
    font-weight: 500;
    color: var(--text-dark);
    letter-spacing: -1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
}

.stats__title {
    font-size: 12px;
    font-weight: 500;
    user-select: none;

    &.-contactable {
        color: var(--plot-contactable);
    }

    &.-contacted {
        color: var(--plot-contacted);
    }

    &.-engaged {
        color: var(--plot-engaged);
    }

    &.-converted {
        color: var(--plot-converted);
    }

    &.-submitted {
        color: var(--plot-submitted);
    }

    &.-replied {
        color: var(--plot-replied);
    }

    &.-value,
    &.-avg-value,
    &.-avg-value-send,
    &.-removed,
    &.-predicted-total-value,
    &.-predicted-avg-value {
        color: var(--status-converted);
    }

    &.-added,
    &.-sent,
    &.-viewed,
    &.-total {
        color: var(--status-sent);
    }

    &.-opened,
    &.-total-opened {
        color: var(--status-opened);
    }

    &.-clicked,
    &.-total-clicked {
        color: var(--status-clicked);
    }

    &.-bounced,
    &.-bounce-rate {
        color: var(--status-bounced);
    }

    &.-invalid {
        color: var(--status-invalid);
    }
}

.stats__view {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 155px;
    background-color: #fff;
    margin-top: 16px;

    & svg {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;

        & .tick text {
            color: var(--text-dark);
            font-size: 10px;

            &.label-year {
                color: var(--text-dark);
                font-weight: 800;
            }
        }

        & .dimple-custom-gridline {
            stroke: var(--colour-slate-50);
            stroke-width: 1;
            shape-rendering: crispEdges;

            &.gridline-year {
                stroke: var(--colour-slate-200);
            }
        }
    }

    & .handle--custom {
        & path,
        & polyline {
            transition: opacity 0.3s ease-out;
            opacity: 0;
        }

        & path {
            fill: var(--colour-blue-50);
        }

        & polyline {
            stroke: var(--colour-blue-200);
            stroke-width: 2px;
        }
    }

    &:hover {
        & .handle--custom {
            & path,
            & polyline {
                opacity: 1;
            }
        }
    }
}

.statsbar__chart {
    width: calc(100% - 45px);
    height: 1em;
    vertical-align: middle;
    text-align: right;
    display: inline-table;

    & span {
        height: 100%;
        display: table-cell;
    }

    & .sent {
        background-color: var(--status-sent);
    }

    & .opened {
        background-color: var(--status-opened);
    }

    & .clicked {
        background-color: var(--status-clicked);
    }

    & .bounced {
        background-color: var(--status-bounced);
    }

    & .unsubscribed {
        background-color: var(--status-unsubscribed);
    }

    & .converted {
        background-color: var(--status-converted);
    }

    & .replied {
        background-color: var(--status-approved);
    }

    & .targeted {
        background-color: var(--status-targeted);
    }
}

.statsbar {
    width: 100%;
    white-space: nowrap;
}

.statsbar__metric {
    width: 65px;
    padding-right: 10px;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    cursor: pointer;
    user-select: none;

    & span,
    & label {
        display: block;
        text-align: right;
        color: var(--dark-text);
    }

    & label {
        font-weight: 500;
        font-size: 9px;
    }

    & .sent label {
        color: var(--status-sent);
    }

    & .opened label {
        color: var(--status-opened);
    }

    & .clicked label {
        color: var(--status-clicked);
    }

    & .bounced label {
        color: var(--status-bounced);
    }

    & .unsubscribed label {
        color: var(--status-unsubscribed);
    }

    & .converted label {
        color: var(--status-converted);
    }

    & .value label {
        color: var(--status-converted);
    }

    & .avg-value label {
        color: var(--status-converted);
    }

    & .avg-value-send label {
        color: var(--status-converted);
    }

    & .replied label {
        color: var(--status-approved);
    }

    & .targeted label {
        color: var(--status-targeted);
    }
}
